const en = {
  demo: {
    title: 'English',
    introduction:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  docs: {
    hi: 'Hi',
    description: 'Need help? \n Please check our docs.',
    documentation: 'documentation',
  },
  app: 'app',
  user: 'user',
  list: 'list',
  edit: 'edit',
  shop: 'shop',
  blog: 'blog',
  post: 'post',
  mail: 'mail',
  chat: 'chat',
  cards: 'cards',
  posts: 'posts',
  create: 'create',
  kanban: 'kanban',
  general: 'general',
  banking: 'banking',
  booking: 'booking',
  profile: 'profile',
  account: 'account',
  product: 'product',
  invoice: 'invoice',
  details: 'details',
  checkout: 'checkout',
  calendar: 'calendar',
  analytics: 'analytics',
  ecommerce: 'e-commerce',
  management: 'management',
  menu_level: 'menu level',
  menu_level_2a: 'menu level 2a',
  menu_level_2b: 'menu level 2b',
  menu_level_3a: 'menu level 3a',
  menu_level_3b: 'menu level 3b',
  menu_level_4a: 'menu level 4a',
  menu_level_4b: 'menu level 4b',
  item_disabled: 'item disabled',
  item_label: 'item label',
  item_caption: 'item caption',
  item_external_link: 'item external link',
  description: 'description',
  other_cases: 'other cases',
  item_by_roles: 'item by roles',
  only_admin_can_see_this_item: 'Only admin can see this item',
  streamline_shipping: 'Streamline your shipping process with',
  simplify_shipping:
    'Simplify your international shipping process with Pochtachi: Reliable, Automated, and Hassle-Free',
  services: 'Services',
  discover_convenience:
    'Discover the convenience and efficiency of our services at Pochtachi. Let us simplify your shipping processes and enhance your overall experience.',
  delivery_list: 'Delivery List Generation',
  automated_invoicing: 'Automated Invoicing',
  goodbye_manual_invoicing:
    'Say goodbye to manual invoice creation. Our system automatically generates invoices, saving you time and eliminating errors.',
  sms_notifications: 'SMS Notifications',
  generate_delivery_list:
    'With our automated delivery list generation, you can effortlessly create comprehensive lists for efficient distribution.',
  keep_customers_informed:
    'Keep your customers informed about their shipments with our SMS feature. They will receive timely updates on the status of their deliveries.',
  customs_documentation: 'Customs Documentation',
  smooth_customs_clearance:
    'Our system prepares all the necessary customs documentation for you ensuring a smooth customs clearance process.',
  unique_parcel_code: 'Unique Parcel Code',
  simplified_distribution:
    'Our unique parcel code system simplifies distribution and allows for easy tracking and management of shipments.',
  customer_support: 'Customer Support',
  ensure_satisfaction:
    'We provide dedicated customer support to assist you with any questions you may have. Our team is here to ensure your satisfaction.',
  why_pochtachi: 'Why Pochtachi?',
  choose_pochtachi: 'Choose Pochtachi and experience the difference in your logistics operations.',
  streamline_operations: 'Streamline Your Operations',
  simplify_automate_tasks:
    'Simplify and automate your daily tasks with our efficient system. Say goodbye to manual processes and save valuable time.',
  enhanced_efficiency: 'Enhanced Efficiency',
  minimize_errors:
    'Our automated processes ensure accuracy and minimize errors, allowing you to work more efficiently and effectively.',
  superior_customer_experience: 'Superior Customer Experience',
  enhance_satisfaction:
    'Impress your customers with timely notifications, accurate invoices, and a seamless delivery process. Enhance customer satisfaction and build long-lasting relationships.',
  customizable_solutions: 'Customizable Solutions',
  tailor_pochtachi:
    'Tailor Pochtachi to match your unique requirements. Customize settings, notifications, and workflows to fit your business needs.',
  real_time_tracking: 'Real-Time Tracking',
  greater_visibility_control:
    'Stay informed about the status of your shipments with our real-time tracking feature. Monitor every step of the delivery process for greater visibility and control.',
  dedicated_support: 'Dedicated Support',
  smooth_experience:
    'Our team of experts is here to assist you at every step. From implementation to ongoing support, we provide personalized assistance for a smooth experience.',
  customer_testimonials: 'Customer Testimonials',
  hear_client_review:
    "Don't just take our word for it - hear what our clients have to say about their experience with Pochtachi",
  pochtachi_numbers: 'Pochtachi in numbers',
  numbers_speak: 'Let the numbers speak for us a little bit:)',
  shipments_sent_out: 'Shipments sent out',
  total_shipped_weight: 'Total shipped weight',
  total_parcels_shipped: 'Total parcels shipped',
  contact_us: 'Contact Us',
  contact_information: 'Contact Information',
  follow_us: 'Follow Us',
  stay_connected:
    'Stay connected with us on social media for the latest news, updates, and promotions.',
  simplifies_shipping:
    "Pochtachi simplifies your international shipping process with its reliable and automated solutions. Experience hassle-free shipping and save time with Pochtachi's streamlined operations.",
  links: 'Links',
  legal: 'Legal',
  contact: 'Contact',
  customers: 'Customers',
  privacy_policy: 'Privacy Policy',
  terms_and_condition: 'Terms and Condition',
  address: 'Shohidontepa 8A,Tashkent, Uzbekistan',
  copyright: '© 2023. All rights reserved',
  osiyoTitle: 'Osiyo Express',
  osiyoDescription:
    'Since switching to Pochtachi, our shipping processes have become incredibly efficient. The automated system saves us a significant amount of time, and the real-time tracking feature allows us to monitor our shipments every step of the way. Our customers are delighted with the timely notifications and seamless delivery experience. Highly recommended!!',
  osiyoAuthor: 'Ilkhom Khujamatov, Osiyo Express (Turkey)',
  humoTitle: 'Humo Export',
  humoDescription:
    'Since switching to Pochtachi, our shipping processes have become incredibly efficient. The automated system saves us a significant amount of time, and the real-time tracking feature allows us to monitor our shipments every step of the way. Our customers are delighted with the timely notifications and seamless delivery experience. Highly recommended!',
  humoAuthor: 'Zukhriddin, Humo Export (Turkey)',
  shipmentSent: 'Shipment sent out',
  totalShippedWeight: 'Total shipped weight',
  totalParcelsShipped: 'Total parcels shipped',
  look_forward: 'We look forward to hearing from you!',
  main: 'Main',
  pochtachi: 'pochtachi',
  wait_your_feedback:
    'We would love to hear from you! Feel free to reach out to us for any inquiries, feedback, or partnership opportunities. ',
};

export default en;
